/*global angular: false, $: false */

let am = angular.module('mtz-directives');

let mtzConfirmScope;

/**
 * This service method returns a promise that is
 * resolved if the user presses the "Yes" button and
 * rejected if they press the "No" button.
 */
am.factory('mtzConfirm', ['$rootScope', $rootScope => {
  let dialog;

  return {
    show(title, question) {
      return new Promise((resolve, reject) => {
        mtzConfirmScope.model = {
          title: title,
          question: question,
          yesFn: resolve,
          noFn: reject
        };

        dialog = $('#mtz-confirm-dialog');
        dialog.modal('show');

        // If not already in a digest cycle, trigger one
        // to refresh dialog with latest content.
        if (!$rootScope.$$phase) $rootScope.$apply();
      });
    },
    hide() {
      dialog.modal('hide');
    }
  };
}]);

/**
 * Example usage:
 * <div mtz-confirm></div>
 * Then call the mtzConfirm service above.
 * For example,
 * mtzConfirm.show('Are you sure?',
 *   'Do you really want to delete the sky?');
 */
am.directive('mtzConfirm', () => ({
  restrict: 'AE',
  replace: true,
  templateUrl: 'feature/share/directives/confirm.html',
  link(scope) {
    mtzConfirmScope = scope;
  }
}));
